import React, { Component } from "react";
import PropTypes, { number } from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import {
  Button,
  Select,
  Input,
  Textarea,
} from "../../../../components/base/index";
import { validateRequiredFields } from "../../../../utils/validation.util";
import { errorAlert } from "../../../../utils/alert.util";

export default class CreateInvoiceTidakRutin extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this.getYearsOfEducationsOptions =
      this.getYearsOfEducationsOptions.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);

    const { user, location } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { organizations_id, school_unit } = user;
    const { va_code, unit_code } = school_unit;
    this.state = {
      form: {
        value: {
          va_code: va_code,
          units_id: school_unit.id,
          unit_code: unit_code,
          no_va: data.no_va,
          academics_year: data.entrance_year,
          students_id: data.id,
          name: data.name,
          organizations_id: organizations_id,
          currents_year: "",
          ttl: 0,
          payments_id: "",
          nominal: 0,
          discount: 0,
          ttl_diskon: 0,
          ttl_potong: 0,
          // total: "",
          dp: 0,
          penyesuaian: 0,
          description: "",
          tipe_potongan: 0,
          // period: "",
          ttl: 0,
          cicilan: [
            {
              total: 0,
              payments_date: null,
            },
          ],
        },
        error: {
          cicilan: [],
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { school_unit, organizations_id } = user;

    const paramTypes = ["classrooms", "levels"];
    if (isEdit && data) {
      this._setForm(data);
    }
    this.getYearsOfEducationsOptions({ units_id: school_unit.id });

    if (organizations_id == 3) {
      this.getPrmPaymentsNon();
    } else {
      this.getPrmPaymentsNonGeneral();
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { form } = this.state;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (type === "number") {
        formattedValue = Number(value);
      }

      if (inputType == "number") {
        formattedValue = normalizeAmount(value);
      }
      if (
        name === "nominal" ||
        name === "dp" ||
        name === "total" ||
        name === "penyesuaian" ||
        name === "ttl_potong"
      ) {
        var toNum = value;
        do {
          toNum = toNum.replace(".", "");
        } while (toNum.indexOf(".") != -1);
        formattedValue = Number(toNum);
      }

      if (name === "discount") {
        if (value > 100) {
          formattedValue = 0;
        }
      }
      if (name === "dp" || name === "penyesuaian") {
        if (formattedValue > form.value.nominal - form.value.ttl_diskon - form.value.ttl_potong) {
          formattedValue = 0;
        }
      }

      if (name === "tipe_potongan") {
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              discount: 0,
              ttl_potong:0
            }
          },
        }));
      }

      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName] || [];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  _onAddList(data) {
    const { form } = this.state;
    const { cicilan } = form.value;

    cicilan.push({
      total: 0,
      payments_date: null,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          cicilan,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.cicilan;
      list.splice(idx, 1);
      return {
        ...prevState,
        cicilan: list,
      };
    });
  }

  async onSubmit() {
    const {
      history,
      handleSaveInvoiceNonRutin,
      handleEditInvoiceNonRutin,
      location,
      handleSaveInvoiceNonRutinGeneral,
      user,
      handleEditInvoiceNonRutinGeneral,
    } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { form } = this.state;
    const { value } = form;
    const { cicilan } = value;
    const { organizations_id, user_group } = user;
    console.log(state.data);
    let canSave = true;
    let error = {};
    if (user_group.name === "pusat" && organizations_id == 3) {
      error = validateRequiredFields(form.value, [
        "currents_year",
        "payments_id",
        "nominal",
        "description",
      ]);
    } else {
      error = validateRequiredFields(form.value, [
        "currents_year",
        "payments_id",
        "nominal",
      ]);
    }
    if (value.dp > value.nominal - value.ttl_diskon - value.ttl_potong) {
      error.dp = "Uang Muka melebihi total bayar.";
    }
    if (value.penyesuaian > value.nominal - value.ttl_diskon - value.ttl_potong) {
      error.penyesuaian = "Penyesuaian melebihi total bayar.";
    }

    // if (value.penyesuaian) {
    //   if ( value.penyesuaian < value.nominal-value.ttl_diskon){
    //     canSave = false;
    //     // error.penyesuaian = "Penyesuaian kurang dari total bayar."
    //     errorAlert({
    //       title: 'Penyesuaian kurang dari total bayar',
    //       errorMessage: 'Mohon untuk di cek kembali.'
    //     });
    //   }
    // }

    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      if (!isEmpty(data.tr_payment_report)) {
        var lunas = 0;
        map(data.tr_payment_report[0].paymentdetails, (list, idx) => {
          if (list.payments_date != null) {
            lunas = lunas + Number(list.nominal);
          }
        });
        console.log(lunas);
        console.log(form.value.ttl);
        if (lunas > form.value.ttl) {
          canSave = false;
          errorAlert({
            title: "Total bayar lebih sedikit dari yang sudah dibayar",
            errorMessage: "Mohon untuk dicek kembali.",
          });
          // error.ttl="Total bayar lebih sedikit dari yang sudah dibayar"
        }
      }
      let payments_date = cicilan.filter((item) => item.payments_date != null);
      console.log(payments_date);

      if (value.ttl <= 0 && payments_date.length == 0) {
        cicilan.splice(0, cicilan.length);
      } else {
        if (isEmpty(cicilan)) {
          canSave = false;
          errorAlert({
            title: "Cicilan Harus Diisi",
            errorMessage: "Mohon tambahkan cicilan terlebih dahulu.",
          });
        } else {
          map(cicilan, (list, idx) => {
            if (list.errcicil || list.errcicil != "") {
              canSave = false;
            } else if (list.total == 0 || list.total == "") {
              canSave = false;
              errorAlert({
                title: 'Cicilan Tidak Boleh "0"',
                errorMessage: "Mohon hapus cicilan yang tidak terpakai.",
              });
            }
          });
        }
      }
      if (canSave) {
        if (isEdit) {
          if (organizations_id == 3) {
            await handleEditInvoiceNonRutin(value);
          } else {
            await handleEditInvoiceNonRutinGeneral(value);
          }
        } else {
          if (organizations_id == 3) {
            await handleSaveInvoiceNonRutin(value);
          } else {
            await handleSaveInvoiceNonRutinGeneral(value);
          }
        }
        history.goBack();
        // history.push("/dashboard/tagihan-siswa/create-invoice");
      }
    }
  }

  async _setForm(data) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { form } = this.state;

    const cicilan = data.tr_payment_report[0].paymentdetails;
    var dp_payments_date = null;
    var dp_invoices_id = null;

    if (data.tr_payment_report[0].dp != "0") {
      dp_payments_date =
        data.tr_payment_report[0].paymentdetails[0].payments_date;
      dp_invoices_id = data.tr_payment_report[0].paymentdetails[0].invoices_id;
      cicilan.shift();
    }

    // if (form.value.penyesuaian & dp_payments_date == null) {
    //   form.value.dp = 0;
    // }

    try {
      this.setState({
        form: {
          value: {
            users_id: data.tr_payment_report[0].users_id,
            no_va: data.no_va,
            name: data.name,
            // address: data.address,
            academics_year: data.tr_payment_report[0].academics_year,
            entrance_year: data.tr_payment_report[0].academics_year,
            id: data.tr_payment_report[0].id,
            students_id: data.id,
            organizations_id: organizations_id,
            currents_year: data.tr_payment_report[0].currents_year,
            payments_id: data.tr_payment_report[0].payments_id,
            nominal: data.tr_payment_report[0].nominal,
            description: data.tr_payment_report[0].description,
            discount: data.tr_payment_report[0].tipe_potongan == 0?data.tr_payment_report[0].discount:0,
            tipe_potongan: data.tr_payment_report[0].tipe_potongan,
            ttl_potong: data.tr_payment_report[0].tipe_potongan == 1?data.tr_payment_report[0].discount:0,
            dp: data.tr_payment_report[0].dp,
            penyesuaian: data.tr_payment_report[0].penyesuaian,
            dp_payments_date: dp_payments_date,
            dp_invoices_id: dp_invoices_id,
            // period:form.value.period ? form.value.period : "0",
            // ttl:form.value.ttl,
            cicilan: map(cicilan, (detail) => ({
              total: detail.nominal,
              payments_date: detail.payments_date,
              invoices_id: detail.invoices_id,
              // edit:true,
            })),
          },
          error: {},
        },
      });
    } catch (err) {
      // err action
    }
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  async getYearsOfEducationsOptions(filters = {}) {
    const { getYearsOfEducationsOptions, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false } = state;

    const res = await getYearsOfEducationsOptions(filters);

    if (!isEdit) {
      map(res, (data, idx) => {
        var date = new Date();
        var start_date = new Date(data.attributes.start_date);
        if (date > start_date) {
          // tahun ajaran selanjutnya
          if (res[idx + 1]) {
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  nextyear: res[idx + 1].value,
                },
              },
            }));
          }
        }
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { school_unit, organizations_id, user_group } = user;
    const { form, param } = this.state;
    const { cicilan, nextyear } = form.value;
    const prm_period = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
      { label: "11", value: "11" },
      { label: "12", value: "12" },
    ];

    const potongan = [{ label: "Persen", value: 0}, {label: "Nominal", value: 1}]
    form.value.currents_year = form.value.currents_year
      ? form.value.currents_year
      : nextyear;

    let diskon_percent = Math.ceil(
        (Number(form.value.nominal) * Number(form.value.discount)) / 100);
    form.value.ttl_diskon = form.value.tipe_potongan == 0 ? diskon_percent : 0;
    form.value.ttl =
      Number(form.value.nominal) -
      Number(form.value.ttl_diskon) -
      Number(form.value.ttl_potong) -
      Number(form.value.dp) -
      Number(form.value.penyesuaian);

    // if (form.value.ttl == 0 || form.value.period == "") {
    //     form.value.period = 0
    // }

    if (form.value.discount == "") {
      form.value.discount = 0;
    }

    // if(Number(form.value.period) !== 0 || form.value.period !="") {
    //   form.value.total = Math.ceil(form.value.ttl/Number(form.value.period));
    // }
    // else{
    //   form.value.total = Math.ceil(form.value.ttl);
    // }
    // if (form.value.ttl == 0) {
    //   cicilan.splice(0, cicilan.length);
    // }

    var sisacicil = form.value.ttl;
    map(cicilan, (list, idx) => {
      sisacicil = sisacicil - list.total;
    });

    if (!isEmpty(cicilan)) {
      var last = cicilan.length - 1;
      map(cicilan, (list, idx) => {
        // if (list.total == 0) {
        //   list.errcicil = "Cicilan tidak boleh 0"
        // } else
        if (sisacicil == 0 || idx != last) {
          list.errcicil = "";
        } else {
          if (sisacicil < 0) {
            list.errcicil = "Cicilan Lebih Rp. " + commaFormatted(sisacicil);
          } else {
            list.errcicil = "Sisa Cicilan Rp. " + commaFormatted(sisacicil);
          }
        }
      });
    }

    console.log(isEdit)

    // if (!isEmpty(data.tr_payment_report)) {
    //   var lunas = 0;
    //   map(data.tr_payment_report[0].paymentdetails, (list, idx) => {
    //     if (list.payments_date != null) {
    //       lunas = lunas + Number(list.nominal)
    //     }
    //   })
    //   if (lunas > form.value.ttl) {
    //     form.error.ttl="Total bayar lebih sedikit dari yang sudah dibayar"
    //   } else {
    //     form.error.ttl=""
    //   }
    // }

    if (form.value.ttl >= form.value.dp) {
      form.error.dp = "";
    }

    if (form.value.ttl >= form.value.penyesuaian) {
      form.error.penyesuaian = "";
    }
    console.log(form.value.dp_payments_date);

    if (form.value.penyesuaian != 0 && form.value.dp_payments_date == null) {
      form.value.dp = 0;
    }

    let payments_date = cicilan.filter((item) => item.payments_date != null);

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>{organizations_id == 3 ? "DPP/UPP" : "NON RUTIN"}</h1>
          <hr></hr>
        </div>
        <form>
          <div className="result__content">
            <div className="nilai-kognitif__custom-form">
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="no_va"
                    label="No. VA"
                    value={data ? data.no_va : ""}
                    onChange={this._onFormChange}
                    error={form.error.no_va || ""}
                    readOnly
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Input
                    name="name"
                    label="Nama"
                    value={data ? data.name : ""}
                    onChange={this._onFormChange}
                    error={form.error.name || ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="entrance_year"
                    label="Tahun Masuk"
                    value={
                      isEdit
                        ? form.value.entrance_year
                        : data
                        ? data.entrance_year
                        : ""
                    }
                    onChange={this._onFormChange}
                    error={form.error.entrance_year || ""}
                    readOnly
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  {/* <Select
                    name="currents_year"
                    label="Tahun Ajaran *"
                    onChange={this._onFormChange}
                    data={param.periods}
                    placeholder={"Pilihan"}
                    value={form.value.currents_year || ""}
                    error={form.error.currents_year}
                    isRequired
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <table className="manage-rapbs-plafon__form-table table2-invoice">
            <thead>
              <tr>
                <th colSpan="2" className="head">
                  Data
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field">
                      <Select
                        label="Nama Pembayaran *"
                        name="payments_id"
                        onChange={this._onFormChange}
                        placeholder="Pilihan"
                        data={param.prm_payments}
                        value={form.value.payments_id}
                        error={form.error.payments_id}
                        isRequired
                        disabled={isEdit ? true : false}
                      />
                    </div>
                    <div
                      className="kode-matpel__custom-form-column__field"
                      style={{ flexDirection: "unset" }}
                    >
                      <label
                        style={{ paddingTop: "30px", paddingRight: "20px" }}
                      >
                        Rp.
                      </label>
                      <Input
                        // type="number"
                        name="nominal"
                        label="Nominal *"
                        onChange={this._onFormChange}
                        value={form.value.nominal}
                        error={form.error.nominal}
                        isNumber
                        isRequired
                      />
                    </div>
                  </div>
                  <div className="kode-matpel__custom-form-row">
                    <div className="kode-matpel__custom-form-row__field">
                      <Select
                        label="Tipe Potongan"
                        name="tipe_potongan"
                        onChange={this._onFormChange}
                        placeholder="Pilihan"
                        data={potongan}
                        value={form.value.tipe_potongan?form.value.tipe_potongan:0}
                        error={form.error.tipe_potongan}
                        isRequired
                        disabled={(isEdit == true && user_group.name == 'pusat') || isEdit == false ? false : true}
                      />
                      <div className="manage-registration__custom-form-row-datadiri">
                      {(form.value.tipe_potongan == 0) ?
                      <Input
                      type="number"
                      name="discount"
                      label="Diskon (%)"
                      onChange={this._onFormChange}
                      value={form.value.discount}
                      error={form.error.discount}
                      isNumber
                      isRequired
                      disabled={(isEdit == true && user_group.name == 'pusat') || isEdit == false ? false : true}
                    />
                      :''}
                      </div>
                    </div>
                    <div
                      className="kode-matpel__custom-form-column__field"
                      style={{ flexDirection: "unset" }}
                    >
                      <label
                        style={{ paddingTop: "30px", paddingRight: "20px" }}
                      >
                        Rp.
                      </label>
                      {form.value.tipe_potongan == 0?
                      <Input
                      name="ttl_diskon"
                      label="Total (Disc)"
                      onChange={this._onFormChange}
                      value={form.value.ttl_diskon}
                      isNumber
                      style={{ backgroundColor: "lightgrey" }}
                      disabled={(isEdit == true && user_group.name == 'pusat') || isEdit == false ? false : true}
                    />
                    :
                      <Input
                      name="ttl_potong"
                      label="Total (Disc) (Nominal)"
                      onChange={this._onFormChange}
                      value={form.value.ttl_potong}
                      isNumber
                      // style={{ backgroundColor: "lightgrey" }}
                      disabled={(isEdit == true && user_group.name == 'pusat') || isEdit == false ? false : true}
                    />  
                    }
                    
                    </div>
                  </div>
                  <div className="kode-matpel__custom-form-row">
                    <div
                      className="kode-matpel__custom-form-row__field"
                      style={{ flexDirection: "unset" }}
                    >
                      {school_unit.unit_code == "P01" ? (
                        <>
                          <Input
                            // type="number"
                            name="penyesuaian"
                            label="Penyesuaian"
                            onChange={this._onFormChange}
                            value={form.value.penyesuaian}
                            error={form.error.penyesuaian}
                            isNumber
                            // isRequired
                            // disabled={form.value.dp_payments_date ? true : false}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="kode-matpel__custom-form-column__field"
                      style={{ flexDirection: "unset" }}
                    >
                      <label
                        style={{ paddingTop: "30px", paddingRight: "20px" }}
                      >
                        Rp.
                      </label>
                      <Input
                        // type="number"
                        name="dp"
                        label="Uang Muka (DP)"
                        onChange={this._onFormChange}
                        value={form.value.dp}
                        error={form.error.dp}
                        isNumber
                        isRequired
                        disabled={form.value.dp_payments_date ? true : false}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field"></div>
                      <div
                        className="kode-matpel__custom-form-column__field"
                        style={{ flexDirection: "unset" }}
                      >
                        <label
                          style={{ paddingTop: "30px", paddingRight: "20px" }}
                        >
                          Rp.
                        </label>
                        <Input
                          name="ttl"
                          label="Total"
                          onChange={this._onFormChange}
                          value={form.value.ttl}
                          error={form.error.ttl}
                          isNumber
                          style={{ backgroundColor: "lightgrey" }}
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              {/* {school_unit.unit_code == 'P01'? */}
              <tr>
                <td>
                  <div>
                    <div className="kode-matpel__custom-form-row">
                      <Textarea
                        name="description"
                        label="Keterangan *"
                        onChange={this._onFormChange}
                        value={form.value.description}
                        error={form.error.description}
                        disabled={user_group.name == "pusat" ? false : true}
                        isRequired
                        // style={{backgroundColor:"lightgrey"}}
                      />
                      <div className="kode-matpel__custom-form-row__field"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  {form.value.ttl == 0 && payments_date.length == 0 ? null : (
                    <div style={{ padding: "20px 50px" }}>
                      <table
                        className="manage-rapbs-plafon__form-table table"
                        style={{ marginBottom: "1rem" }}
                      >
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Cicilan</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(cicilan, (list, idx) => (
                            <tr key={`plafon_journal_data__${idx}`}>
                              <td style={{ textAlign: "center" }}>
                                {idx + 1}.
                              </td>
                              <td>
                                <div style={{ display: "flex" }}>
                                  <label
                                    style={{
                                      paddingTop: "30px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    Rp.
                                  </label>
                                  <Input
                                    // type="number"
                                    name="total"
                                    label={"Cicilan " + (idx + 1) + " *"}
                                    onChange={this._onFormChange}
                                    value={list.total}
                                    // error={form.error.dp}
                                    error={list.errcicil}
                                    data-input-array
                                    arrayPosition={idx}
                                    data-array-position={idx}
                                    data-field-name="cicilan"
                                    isNumber
                                    isRequired
                                    disabled={
                                      list.payments_date != null ? true : false
                                    }
                                  />
                                </div>
                              </td>
                              {!isEdit || list.payments_date == null ? (
                                <td>
                                  <Button
                                    onClick={() => this._onDeleteList(idx)}
                                    icon="icon-trash-o"
                                  ></Button>
                                </td>
                              ) : (
                                <td
                                  className="reportspp"
                                  style={{
                                    color: "green",
                                    textAlign: "center",
                                  }}
                                >
                                  LUNAS
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* <div className="kode-matpel__custom-form-row">
                        <div className="kode-matpel__custom-form-row__field" style={{flexDirection: "unset"}}>
                        </div>
                        <div className="kode-matpel__custom-form-column__field" style={{marginBottom: "1rem"}}>
                          <label style={{fontSize: "1.6rem"}}>Sisa Cicilan: Rp. {commaFormatted(sisacicil)}</label>
                        </div>
                      </div> */}
                      <div>
                        <Button icon="icon-plus" onClick={this._onAddList} />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="student-list__header">
            <div className="student-list__button">
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CreateInvoiceTidakRutin.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CreateInvoiceTidakRutin.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
