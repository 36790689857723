import { connect } from 'react-redux';
import AbsensiRekap from '../../../views/Student/absensi/AbsensiRekap.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getLevels, getStudentOptions} from '../../../states/thunks/options.thunk';
import {getParamOptions, getParamOptionsAcademics, getPeriodsOptions, getMonthAcademicsOptions, getPrmLevels, getStudentPeriodOptions, getPrmMapelOptions, getClassesOptions} from '../../../states/thunks/options.thunk';
import { listStudentRekapAttendance, studentCount, listRekapAttendanceMapel } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
      user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMonthAcademicsOptions: (payload) => dispatch(getMonthAcademicsOptions(payload)),
    handleListStudentRekapAttendance: (payload, goback) => dispatch(listStudentRekapAttendance(payload, goback)),
    handleListRekapAttendanceMapel: (payload, goback) => dispatch(listRekapAttendanceMapel(payload, goback)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetStudent: () => dispatch(getStudent()),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    handleStudentCount: (payload) => dispatch(studentCount(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getStudentPeriodOptions: (payload) => dispatch(getStudentPeriodOptions(payload)),
    getPrmMapelOptions: (payload) => dispatch(getPrmMapelOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsensiRekap);


