import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button, SearchSelect } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';
import { Link } from "react-router-dom";
import * as commonActions from '../../../../states/actions/common.action';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanSPP extends PureComponent {
  constructor(props) {
    super(props);
    
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickStudent = this._onClickStudent.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._onClickSubmit = this._onClickSubmit.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onSearchStudent = this._onSearchStudent.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);
    this._onClickDetails = this._onClickDetails.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      list: [],
      filters:{
      },
      list: [],
      isPrint: true,
      form: {
        value: {
          levels_id,
          listreport: [],
          listreport2: [],
          units_id:'',
          levels_units:'',
          user_id:user.id
        },
        error: {
          listreport: [],
          listreport2: [],
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { content, filters, form } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem('invoiceFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('invoiceForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    console.log(savedFilters)
    console.log(savedForm)
    if (isEmpty(content.list)) {
    }
    this._initializeData(savedFilters);
    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedForm.value.units_id,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedForm.value.units_id,
        }
      }));
     
      if(user_group.name === 'pusat'){
         this._getPeriodsOptions({units_id: savedForm.value.units_id.value});
         this._getParamOptionsAcademics("classrooms", {
          levels_id: savedForm.value.levels_id,
          organizations_id,
          units_id: savedForm.value.units_id.value
        });
        }
        else {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: savedForm.value.levels_id,
            organizations_id,
            // units_id: savedForm.value.units_id.value
          });
        }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { form } = this.state;
    const { school_unit, user_group, organizations_id } = user
    const paramTypesLevels = ["levels"];
    paramTypesLevels.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
   
    if(user_group.name !== 'pusat'){
      const paramTypes = ["classrooms", "levels"];

      paramTypes.forEach((type) => {
        this._getParamOptionsAcademics(type);
      });
      this._getPeriodsOptions({units_id: school_unit.id});
    }

    await this._getSchoolUnitsOptions();

    if ( organizations_id == 3){
      await this.getPrmPaymentsNon();
    }
    else {
    await this.getPrmPaymentsNonGeneral();
    }

    if (user_group.name == 'pusat'){
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id : savedFilters.classrooms_id,
          jenis : savedFilters.jenis,
          payments_id : savedFilters.payments_id, 
          period : savedFilters.period,
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
    else {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id : savedFilters.classrooms_id,
          jenis : savedFilters.jenis,
          payments_id : savedFilters.payments_id, 
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
   
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      // window.localStorage.setItem('invoiceValue', JSON.stringify(this.state.form));
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    },
    () => {
      if (name === "levels_id") {
        this._getParamOptionsAcademics("classrooms", {
          levels_id: value,
          organizations_id: organizations_id
        });
      }
    });
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { form } = this.state;
    const { organizations_id, school_unit } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('invoiceFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('invoiceForm', JSON.stringify(this.state.form));
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id, units_id: school_unit.id },
          });
        }
        if (name === 'units_id') {
          const param = this.state.param.units;
          console.log(value.value)
          const levels_units = param.find(i => i.value == value.value)?param.find(i => i.value == value.value).attributes.levels_id:"";
          
          console.log(levels_units)
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                levels_id: levels_units,
                units_id : value,
              }
            },
            filters : {
              ...prevState.filters,
              jenis: "",
            }
          }));
          this._getParamOptionsAcademics("classrooms", {
            levels_id: levels_units, organizations_id: organizations_id, units_id: value.value
          });
          this._getPeriodsOptions({units_id: value.value})
        }

        this._onSearchContent();
      }
    );
  }

  _onClickStudent(data, idx) {
    this._onSearchStudent(data);
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
      data_idx: idx,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }

  _onClickSubmit() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp");
  }

  _onClickEdit(val) {
    const { history, user } = this.props;
    const { organizations_id}  = user;
    // console.log("ini val" +val)
    if(organizations_id == 4 ||  organizations_id == 5){
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general", {data: val, isEdit: true });
    }
    else {
      history.push('/dashboard/tagihan-siswa/create-invoice/tidak-rutin', { data: val, isEdit: true });
    }
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { currents_year } = form.value
    const { jenis, classrooms_id, period, payments_id } = filters;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleReportPaymentsRutin,handleReportPaymentsRutinGeneral, 
            handleReportPaymentsNonRutin, handleReportPaymentsNonRutinNew, handleReportPaymentsNonRutinGeneral,
            handleReportPaymentsNonRutinGeneralRicci } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id} = user;
          const { units_id } = school_unit.id;

          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if (month < 7) {
            year -= 1;
          }

          // let period_label = param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""
          // if (period_label && period_label != "") {
          //   let period_idx = period_label.indexOf("-");
          //   let currents_year = period_label.substring(0, period_idx)
          // }

          let result = {};
          let result2 = {};
          if (jenis === "rutin" && period && organizations_id == 3) {
            result = await handleReportPaymentsRutin({
              ...params,
              organizations_id : organizations_id,
              // currents_year : period ? period : year,
              currents_year : currents_year ? currents_year : year,
              classrooms_id : classrooms_id,
              filters,
            });
          } 
          else if (jenis === "rutin" && period && organizations_id !== 3) {
            result = await handleReportPaymentsRutinGeneral({
              ...params,
              organizations_id : organizations_id,
              // currents_year : period ? period : year,
              currents_year : currents_year ? currents_year : year,
              classrooms_id : classrooms_id,
              filters,
            });
          } 
          else if (jenis === "nonrutin" && payments_id && organizations_id == 3) {
            result2 = await handleReportPaymentsNonRutin({
              ...params,
              organizations_id : organizations_id,
              payments_id: payments_id,
              classrooms_id : classrooms_id,
              filters,
            });
          }
          else if (jenis === "nonrutin" && payments_id && organizations_id == 4 || organizations_id == 5) {
            result2 = await handleReportPaymentsNonRutinGeneralRicci({
              ...params,
              organizations_id : organizations_id,
              payments_id: payments_id,
              classrooms_id : classrooms_id,
              filters,
            });
          }
          else if (jenis === "nonrutin" && payments_id && organizations_id !== 3 && organizations_id !== 4 || organizations_id !== 5) {
            result2 = await handleReportPaymentsNonRutinGeneral({
              ...params,
              organizations_id : organizations_id,
              payments_id: payments_id,
              classrooms_id : classrooms_id,
              filters,
            });
          }

          
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: result,
                listreport2: result2,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: [],
                listreport2: [],
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  async _onSearchStudent(data) {
    const { filters, form } = this.state;
    const { jenis, classrooms_id, period, payments_id } = filters;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleReportPaymentsNonRutinNew } = this.props;
          const { user } = this.props;
          const { organizations_id} = user;

          let result3 = {};
          result3 = await handleReportPaymentsNonRutinNew({
            organizations_id : organizations_id,
            payments_id: payments_id,
            temps_id : data.no_va,
            // filters,
          });
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listcicilan: result3,
                student_name: data.name,
                student_id: data.no_va,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listcicilan: [],
                student_name: "",
                student_id: "",
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    const { form } = this.state;
    const { units_id } = form.value;
    
    if (user_group.name !== 'pusat'){
      if (type === "classrooms" || type === "classrooms") {
        filters.organizations_id = organizations_id;
        {units_id?filters.units_id = units_id : filters.units_id = school_unit.id}
        
        // filters.units_id = 121;
      } else {
        delete filters.organizations_id;
      }
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }
  async _getSchoolUnitsOptions(filters={}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find(item => item.value == units_id); 
    
    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '', 
        }
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  _onClickDetails(val) {
    const { history } = this.props;
    const { form, filters, param, page, isPrint, data_idx } = this.state;
    const { listreport, listreport2, years_now, currents_year } = form.value;
    history.push("/dashboard/tagihan-siswa/laporan-spp-excel", {
      data1: listreport,
      data2: listreport2,
    });
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
    const { form, filters, param, page, isPrint, data_idx } = this.state;
    const { listreport, listreport2, listcicilan, student_id,  student_name } = form.value;
    const { classrooms_id = "", jenis, period = "", payments_id = "", units_id } = filters;
    const { user } = this.props
    const { organization, school_unit, user_group, organizations_id } = user;
    const { logo_right } = organization;
    const { prm_payments } = param;
    // console.log(form.value.units_id)
    const componentRef = React.createRef();
    
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;

    const prm_jenis = [
      { label: organizations_id ==3?'SPP':'RUTIN', value: 'rutin'},
      { label: organizations_id ==3?'DPP/UPP':'NON RUTIN', value: 'nonrutin'},
    ];
    

    const bulan = [
      { label: 'Juli'},
      { label: 'Agustus'},
      { label: 'September'},
      { label: 'Oktober'},
      { label: 'November'},
      { label: 'Desember'},
      { label: 'Januari'},
      { label: 'Februari'},
      { label: 'Maret'},
      { label: 'April'},
      { label: 'Mei'},
      { label: 'Juni'},
    ];

    const cicilan = [
      { label: 'DP'},
      { label: 'Cicilan 1'},
      { label: 'Cicilan 2'},
      { label: 'Cicilan 3'},
      { label: 'Cicilan 4'},
      { label: 'Cicilan 5'},
      { label: 'Cicilan 6'},
      { label: 'Cicilan 7'},
      { label: 'Cicilan 8'},
      { label: 'Cicilan 9'},
      { label: 'Cicilan 10'},
      { label: 'Cicilan 11'},
      { label: 'Cicilan 12'},
    ];

    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }
    // console.log(param.periods?param.period.find(i => i.value == period):'')

    if(!isEmpty(param.periods) && period) {
      let period_label = param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""
      if (period_label && period_label != "") {
        let period_idx = period_label.indexOf("-");
        form.value.currents_year = period_label.substring(0, period_idx)
      }
    }

    var lunas = 0
    var ttllunas = 0
    var subtotal = 0
    var all_total = 0
    var totalAwal = 0
    var total = 0
    var classrooms_name;
    var payments_name;

    map(param.classrooms, (data, idx) => {
      if (data.value == classrooms_id) {
        classrooms_name = data.label
      }
    })
    map(param.prm_payments, (data, idx) => {
      if (data.value == payments_id) {
        payments_name = data.label
      }
    })

    var years = []
    var thisyear = new Date().getFullYear();
    
    for(var i=2006; i<=thisyear; i++) {
      var nextyear=i+1
      years.push({
        label: i+"/"+nextyear,
        value: i,
      });
    }
    var title = ""
    if (jenis == "rutin") {
      title = organizations_id ==3?'SPP':'RUTIN'
    }
    if (jenis == "nonrutin") {
      title = organizations_id ==3?'DPP/UPP':'NON RUTIN'
    }

    let prm_payments_main = prm_payments?prm_payments.filter((item) => item.is_type == 1):'';
   
    const units_label = units_id?units_id.label:'';

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Edit Tagihan</h1>
          <hr></hr>
        </div>

        {page === 1 && (
        
          <div className="absensi-rekap__custom-form">
          {(user_group.name === 'pusat')? 
              <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="units_id"
                list={param.units}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Unit"
                value={form.value.units_id}
                rightIcon="icon-search"
                label="Pilih Unit"
              />
                {/* <Select
                  type="text"
                  name="units_id"
                  label="Unit"
                  placeholder="Pilih Unit"
                  data={param.units}
                  value={form.value.units_id}
                  onChange={this._onChangeFilter}
                  error={form.error.units_id || ""}
                /> */}
              </div>
            </div>
            :''}
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  type="text"
                  name="levels_id"
                  label="Unit"
                  placeholder="Pilih Unit"
                  data={param.levels}
                  disabled
                  value={form.value.levels_id}
                  onChange={this._onFormChange}
                  error={form.error.levels_id || ""}
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                {(form.value.levels_id) && (
                  <Select
                    type="text"
                    name="classrooms_id"
                    label="Kelas"
                    placeholder="Pilih Kelas"
                    data={param.classrooms}
                    onChange={this._onChangeFilter}
                    value={classrooms_id}
                  />
                )}
              </div>
            </div>

            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                {(classrooms_id) && (
                  <Select
                    name="jenis"
                    label="Jenis Pembayaran"
                    onChange={this._onChangeFilter}
                    data={prm_jenis}
                    placeholder="Pilihan"
                    value={jenis}
                  />
                )}
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                {(classrooms_id && (jenis === "rutin")) && (
                  <Select
                    name="period"
                    label="Tahun Ajaran"
                    onChange={this._onChangeFilter}
                    data={param.periods}
                    disabled={user_group.name == 'pusat'? false : true}
                    // data={years}
                    placeholder={"Pilihan"}
                    value={period ? period : year}
                  />
                )}
                {(classrooms_id && jenis === "nonrutin") && (
                  <Select
                    name="payments_id"
                    label="Nama Pembayaran"
                    onChange={this._onChangeFilter}
                    data={organizations_id == 3 ? prm_payments : prm_payments_main}
                    placeholder={"Pilihan"}
                    value={payments_id}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <br></br>
        <div className="beginning-balance__button-wrapper">
          {page === 2 && (
              <Button onClick={this._onClickBack} title="Kembali" />
          )}
          <ReactToPrint
            pageStyle={printTest}
            onBeforeGetContent={()=> {
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.setState(
                    { isPrint: false },
                    resolve
                  );
                }, 500);
              });
            }}
            onAfterPrint={()=> this.setState({isPrint: true})}
            trigger={() => (
              // <div className="manage-registration__footer">
                <Button title="Cetak" />
              // </div>
            )}
            content={() => componentRef.current}
          />
           &nbsp;
            {jenis === 'nonrutin' && organizations_id !== 3 ?
              <div style={{ marginLeft: '0rem' }} className="student-list__button">
                <Button
                  title="Detail Laporan"
                  onClick={this._onClickDetails}
                />
              </div>
              : ''}
        </div>
        {/* {isPrint? */}
        <form style={{ pageBreakInside: 'avoid', paddingTop: '20px' }} ref={componentRef}>
          <div>
            <table className="table-report-header">
              <tr>
                <th rowSpan="2">
                  <img style={{width:"70px"}} src={logo_right} />
                </th>
                <th style={{ paddingRight: '10rem' }}>
                  <h2>{user_group.name === 'pusat' ? units_label : school_unit.name}</h2>
                </th>
              </tr>
              <tr>
                <th style={{ paddingRight: '10rem' }}>
                  <h3>EDIT TAGIHAN {payments_name}</h3>
                </th>
              </tr>
            </table>

            {(() => {
              if (jenis === "rutin") {
                return (
                  <>
                  <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                    <tr>
                      <td>Jenis Pembayaran </td>
                      <td>&emsp; : {title}</td>
                    </tr>
                    <tr>
                      <td>Tahun Ajaran</td>
                      {/* <td>&emsp; : {form.value.currents_year ? form.value.currents_year+"/"+(Number(form.value.currents_year)+1) : year+"/"+(Number(year)+1)}</td> */}
                      <td>&emsp; : {param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""}</td>
                    </tr>
                    <tr>
                      <td>Kelas</td>
                      <td>&emsp; : {classrooms_name ? classrooms_name : "-"}</td>
                    </tr>
                  </table>
                  <br></br>
                  <table className="tablespp">
                    <thead>
                      <tr>
                        <th rowSpan={2}>No</th>
                        <th rowSpan={2}>No VA</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={12}>Rincian Pembayaran</th>
                        <th rowSpan={2}>Total Pembayaran</th>
                      </tr>
                      <tr>
                        {map(bulan, (bln, idx) => (
                          <th>{bln.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {map(listreport, (data, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {(() => {
                            subtotal = 0
                          })()}
                          <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                            {idx + 1}
                          </td>
                          <td>{data.no_va}</td>
                          <td style={{textTransform:"capitalize"}}>{capitalize(data.name)}</td>
                          {(() => {
                            if (isEmpty(data.tr_invoices)) {
                              return (
                                <td colSpan={12}>0</td>
                              )
                            } else {
                              return (
                                map(data.tr_invoices, (data_detail, idx_detail) => {
                                  if (data_detail.payments_date !== null) {
                                    return (
                                      <td className="reportspp" style={{color:"green", textAlign:"center"}}>LUNAS</td>
                                    )
                                  } else {
                                    subtotal = subtotal + Number(data_detail.nominal)
                                    return (
                                      <td className="reportspp">
                                        <Link
                                          to={{
                                            pathname: "/dashboard/tagihan-siswa/create-invoice/rutin",
                                            state: {
                                              getdata: data_detail,
                                              data: data, 
                                              isEdit: true,
                                            },
                                          }}>
                                          {commaFormatted(data_detail.nominal)}
                                        </Link>
                                      </td>
                                    )
                                  }
                                })
                              )
                            }
                          })()}
                          <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(subtotal)}</td>
                          {(() => {
                            total = total + subtotal
                          })()}
                        </tr>
                      ))}
                      <tr>
                        <th colSpan={15}>Total</th>
                        <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(total)}</th>
                      </tr>
                    </tbody>
                  </table>
                  </>
                )
              }
              if (jenis === "nonrutin" && payments_id){
                return (
                  <>
                  {page === 1 && (
                    <div>
                      <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                       
                        <tr>
                          <td>Nama Pembayaran</td>
                          <td>&emsp; : {payments_name ? payments_name : "-"}</td>
                        </tr>
                        <tr>
                          <td>Kelas</td>
                          <td>&emsp; : {classrooms_name ? classrooms_name : "-"}</td>
                        </tr>
                      </table>
                      <br></br>
                      <table className="tablespp">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>No VA</th>
                            <th>Nama Siswa</th>
                            <th>Total Kesanggupan</th>
                            <th>Total Pembayaran</th>
                            <th>Total Tunggakan</th>
                            {isPrint?<th>Opsi</th>:null}
                          </tr>
                        </thead>
                        <tbody>
                          {map(listreport2, (data, idx) => (
                            <tr key={`budget_row_${idx}`}>
                              {(() => {
                                lunas = 0
                                subtotal = 0
                                all_total = 0
                              })()}
                              <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                                {idx + 1}
                              </td>
                              <td>{data.no_va}</td>
                              <td>
                                <Button className="button-student" 
                                  onClick={() => this._onClickStudent(data, idx)}
                                  title={capitalize(data.name)} 
                                />
                              </td>
                              {(() => {
                                if (!isEmpty(data.tr_payment_report) && !isEmpty(data.tr_payment_report[0].paymentdetails)) {
                                  map(data.tr_payment_report[0].paymentdetails, (data_detail, idx_detail) => {
                                    if (data_detail.payments_date !== null) {
                                      lunas = lunas + Number(data_detail.nominal)
                                    } else {
                                      subtotal = subtotal + Number(data_detail.nominal)
                                    }
                                    all_total = lunas + subtotal
                                  })
                                  return (
                                    <>
                                      <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(all_total)}</td>
                                      <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(lunas)}</td>
                                      <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(subtotal)}</td>
                                      {isPrint?
                                        <td>
                                          <Button
                                            onClick={()=> this._onClickEdit(data)}
                                            title = "Edit"
                                          />
                                        </td>
                                      :null}
                                    </>
                                  )
                                } else {
                                  return (
                                    <td colSpan={3}>0</td>
                                  )
                                }
                              })()}
                              {(() => {
                                ttllunas = ttllunas + lunas
                                total = total + subtotal
                                totalAwal = totalAwal + all_total
                              })()}
                            </tr>
                          ))}
                          <tr>
                            <th colSpan={3}>Total</th>
                            <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(totalAwal)}</th>
                            <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(ttllunas)}</th>
                            <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(total)}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  {page === 2 && (
                    <div>
                      <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                        <tr>
                          <td>Jenis Pembayaran </td>
                          <td>&emsp; : {title}</td>
                        </tr>
                        <tr>
                          <td>Nama Pembayaran</td>
                          <td>&emsp; : {payments_name ? payments_name : "-"}</td>
                        </tr>
                        <tr>
                          <td>No. VA</td>
                          <td>&emsp; : {student_id ? student_id : "-"}</td>
                        </tr>
                        <tr>
                          <td>Nama Siswa</td>
                          <td>&emsp; : <span style={{textTransform:"capitalize"}}>{student_name ? capitalize(student_name) : "-"}</span></td>
                        </tr>
                        <tr>
                          <td>Kelas</td>
                          <td>&emsp; : {classrooms_name ? classrooms_name : "-"}</td>
                        </tr>
                      </table>
                      
                      {!isEmpty(listcicilan) ? (
                        <>
                          <table className="laporan-ki4__table-test" style={{width:"100%"}}>
                            <tr>
                              <td>&emsp;</td>
                              {isPrint?
                                <td width={"10%"} style={{padding: "10px"}}>
                                  <Button
                                    onClick={()=> this._onClickEdit(listreport2[data_idx])}
                                    title = "Edit"
                                  />
                                </td>
                              :null}
                            </tr>
                          </table>
                          <br></br>
    
                          <table className="tablespp">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Cicilan</th>
                                <th>Nominal</th>
                                <th>Status</th>
                                {/* {isPrint?<th>Opsi</th>:null} */}
                              </tr>
                            </thead>
                            <tbody>
                              {!isEmpty(listcicilan) && (
                                map(listcicilan[0].paymentdetails, (data, idx) => (
                                  <tr key={`budget_row_${idx}`}>
                                    {(() => {
                                      total = total + Number(data.nominal)
                                      if (data.cicilan == "Cicilan 0") {
                                        data.cicilan = "DP"
                                      }
                                    })()}
                                    <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                                      {idx + 1}
                                    </td>
                                    <td>{data.cicilan}</td>
                                    <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(data.nominal)}</td>
                                    {(() => {
                                      if (data.payments_date !== null) {
                                        return (
                                          <td className="reportspp" style={{color:"green", textAlign:"center"}}>LUNAS</td>
                                        )
                                      } else {
                                        return (
                                          <td className="reportspp" style={{color:"red", textAlign:"center", boxSizing:"unset"}}>BELUM LUNAS</td>
                                        )
                                      }
                                    })()}
                                    {/* {isPrint?
                                      <td>
                                        <Button
                                          // onClick={()=> this._onClickEdit()}
                                          title = "Edit"
                                        />
                                      </td>
                                    :null} */}
                                  </tr>
                                ))
                              )}
                              <tr>
                                <th colSpan={2}>Total</th>
                                <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(total)}</th>
                                <th></th>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          <br></br>
                          <br></br>
                          <h2>Invoice belum dibuat.</h2>
                        </>
                      )}

                    </div>
                  )}
                  </>
                )
              }
            })()}
          </div>
        </form>

      </div>
    );
  }
}
LaporanSPP.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanSPP.defaultProps = {
  handleGetStudent: noop,
};
