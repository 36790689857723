import { connect } from 'react-redux';
import LaporanSPPExcel from '../../../../views/Finance/Spp/LaporanSPP/LaporanSPPExcel.view';
import { reportPaymentsRutin, reportPaymentsNonRutin, reportPaymentsNonRutinNew, reportPaymentsRutinGeneral, 
    reportPaymentsNonRutinGeneral, reportPaymentsNonRutinGeneralRicci } from '../../../../states/thunks/academics.thunk';
  import { getPrmLevels, getParamOptionsAcademics, getYearsOfEducationsOptions, getPrmPaymentsNon, getPeriodsOptions, getSchoolUnitsOptions, getPrmPaymentsNonGeneral } from '../../../../states/thunks/options.thunk';
  
  function mapStateToProps(state) {
    return {
      classes: state.options.classes,
      levels: state.options.levels,
      user: state.user || {},
    }
  }
  function mapDispatchToProps(dispatch) {
    return {
      handleReportPaymentsRutin: (payload, goback) => dispatch(reportPaymentsRutin(payload, goback)),
      handleReportPaymentsNonRutin: (payload, goback) => dispatch(reportPaymentsNonRutin(payload, goback)),
      handleReportPaymentsNonRutinGeneral: (payload, goback) => dispatch(reportPaymentsNonRutinGeneral(payload, goback)),
      handleReportPaymentsNonRutinNew: (payload, goback) => dispatch(reportPaymentsNonRutinNew(payload, goback)),
      getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
      getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
      getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
      getPrmPaymentsNon: (payload) => dispatch(getPrmPaymentsNon(payload)),
      getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
      getSchoolUnitsOptions: (payload) => dispatch(getSchoolUnitsOptions(payload)),
      handleReportPaymentsRutinGeneral: (payload, goback) => dispatch(reportPaymentsRutinGeneral(payload, goback)),
      getPrmPaymentsNonGeneral: (payload) => dispatch(getPrmPaymentsNonGeneral(payload)),
      handleReportPaymentsNonRutinGeneralRicci: (payload, goback) => dispatch(reportPaymentsNonRutinGeneralRicci(payload, goback)),
   
    };
  }
export default connect(mapStateToProps, mapDispatchToProps)(LaporanSPPExcel);
