import React from "react";
import PropTypes from "prop-types";
import { noop, map, isEqual } from "lodash";
import { Button, Pagination, SearchSelect } from "../../../components/base";
import BaseTable from "../../../components/BaseTable/BaseTable.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { formatData } from "../../../utils/transformer.util";
import language from "../../../languages";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { PENGUMUMAN_LIST_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Pengumuman extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddFolder = this.onClickAddFolder.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      content: initialContent,
      list: [],
      filters: {},
      form: {
        value: {
          total_siswa: "",
          classes: [],
          levels_id,
          units_id: "",
          listsiswa: [],
          classes_id: "",
          user_id: user.id,
        },
        error: {
          classes: "",
          levels: "",
          listsiswa: "",
        },
      },
      workflow: {},
      listAmount: 5,
      param: {},
    };
  }

  // componentDidMount() {
  //   const { content } = this.state;
  //   this.handleGetData();
  //   this._getSchoolUnitsOptions();

  // }

  componentDidMount() {
    const { user } = this.props;
    const { organizations_id, user_group } = user;
    const { content, form, filters } = this.state;
    let savedForm =
      JSON.parse(window.localStorage.getItem("announcementForm")) || "";
    let savedFilters =
      JSON.parse(window.localStorage.getItem("announcementFIlters")) || filters;
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    console.log(savedFilters);
    this._initializeData(savedFilters);

    if (savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedFilters.units_id ? savedFilters.units_id : "",
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedFilters.units_id ? savedFilters.units_id : "",
        },
      }));
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { school_unit, user_group } = user;
    const { levels_id } = school_unit;

    await this._getSchoolUnitsOptions();

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id: savedFilters.classrooms_id,
          // periods: savedFilters.periods
        },
      }),
      () => {
        // commonActions.setLoading({ show: true });
        this.handleGetData();
      }
    );
  }

  async onClickDownload(id) {
    const { handleDownloadAnnouncement } = this.props;
    const res = await handleDownloadAnnouncement(id.id, id.upload);
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async handleGetData(payload = {}) {
    const { filters, form, param } = this.state;
    const { handleListAnnouncement, handleListAnnouncementStudent, user } =
      this.props;
    const {
      workingUnit,
      id,
      user_group,
      profile,
      organizations_id,
      school_unit,
    } = user;

    const { units_id } = filters;

    console.log(filters);

    // if(user_group.id === 2){
    const res = await handleListAnnouncement({
      ...payload,
      user_id: id,
      teacher_id: id,
      organizations_id: organizations_id,
      units_id: units_id ? units_id.value : school_unit.id,
    });

    this.setState({
      list: res,
    });
    // }

    // if(user_group.id === 1){
    //   const res = await handleListAnnouncementStudent({...payload, user_id: id, students_id: profile.id, organizations_id: organizations_id });

    //   this.setState({
    //     list: res,
    //   });
    // }
  }

  onClickAdd(units_id, levels_id) {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/tambah-pengumuman", {
      isEdit: true,
      data: { units_id: units_id, levels_id: levels_id },
    });
  }

  onClickAddFolder() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/tambah-folder", { isEdit: true });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/tambah-pengumuman", {
      data: val,
      isEdit: true,
    });
  }
  async onClickDelete(id) {
    const { handleDeleteAnnouncement } = this.props;
    const res = await handleDeleteAnnouncement(id);
    if (res) {
      this.handleGetData();
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const button = {};

    return (
      <>
        <td>
          {data.upload ? (
            <Button
              onClick={() => this.onClickDownload(data)}
              title="Download"
            />
          ) : (
            <div></div>
          )}
        </td>
        <td>
          <div className="table__actions">
            <Button onClick={() => this.onClickEdit(data)} title="Edit" />

            <Button onClick={() => this.onClickDelete(data)} title="Hapus" />
          </div>
        </td>
      </>
    );
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { form } = this.state;
    const { organizations_id, school_unit } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem(
          "announcementForm",
          JSON.stringify(this.state.form)
        );
        window.localStorage.setItem(
          "announcementFIlters",
          JSON.stringify(this.state.filters)
        );
        if (name === "units_id") {
          const param = this.state.param.units;
          const levels_units = param.find((i) => i.value == value.value)
            ? param.find((i) => i.value == value.value).attributes.levels_id
            : "";

          console.log(value);
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                levels_id: levels_units,
                units_id: value,
              },
            },
          }));
        }
        console.log(form.value);
        this.handleGetData();
      }
    );
  }

  render() {
    const { list = {}, form, param } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const today = new Date();
    const dataset = {
      toggle: "modal",
    };
    console.log(form.value.units_id);
    const get_units = form.value.units_id.value;
    const get_levels = form.value.units_id
      ? form.value.units_id.attributes
        ? form.value.units_id.attributes.levels_id
        : ""
      : "";
      console.log(today)
    return (
      <div className="fund-request">
        {user_group.name === "pusat" ? (
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="units_id"
                list={param.units}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Unit"
                value={form.value.units_id}
                rightIcon="icon-search"
                label="Pilih Unit"
              />
              {/* <Select
                  type="text"
                  name="units_id"
                  label="Unit"
                  placeholder="Pilih Unit"
                  data={param.units}
                  value={form.value.units_id}
                  onChange={this._onChangeFilter}
                  error={form.error.units_id || ""}
                /> */}
            </div>
          </div>
        ) : (
          ""
        )}
        <br></br>
        <div className="beginning-balance__button-wrapper">
          <Button
            title="Tambah Pengumuman"
            onClick={() => this.onClickAdd(get_units, get_levels)}
            data={dataset}
          />
        </div>

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {/* {
                    map(PENGUMUMAN_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  } */}
                <th rowSpan={2} className="grey">
                  No
                </th>
                <th colSpan={2} className="grey">
                  Tanggal Terbit
                </th>
                <th rowSpan={2} className="grey">
                  Judul Pengumuman
                </th>
                <th rowSpan={2} className="grey">
                  Tautan (URL)
                </th>
                <th rowSpan={2} className="grey">
                  File
                </th>
                <th rowSpan={2} className="grey"></th>
                <th rowSpan={2} className="grey">
                  Status
                </th>
              </tr>
              <tr>
                <th className="grey" style={{ width: "10%" }}>
                  Mulai
                </th>
                <th className="grey" style={{ width: "10%" }}>
                  Selesai
                </th>
              </tr>
            </thead>
            <tbody>
              {map(list.result, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  <td
                    key={`table_index_${idx}`}
                    style={{ textAlign: "center" }}
                  >
                    {idx + 1}
                  </td>
                  <td>{data.publish_date}</td>
                  <td style={{ textAlign: "center" }}>{data.end_date}</td>
                  <td style={{ textAlign: "center" }}>{data.name}</td>
                  <td key={`table_${idx}`}>
                    <a
                      href={data.hyperlink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.hyperlink}
                    </a>
                  </td>
                  {/* <td style={{textAlign:"center"}}>{data.hyperlink}</td> */}
                  {this._renderButtons(data)}
                  {/* <td style={{ textAlign: "center" }}>{data.url_upload}</td> */}
                  {(() => {
                    // console.log(new Date(data.publish_date))
                    const publish = new Date(data.publish_date)
                    const end = new Date(data.end_date)
                    if (data.is_active == 1) {
                      return (
                        <td
                          className="reportspp"
                          style={{ color: "green", textAlign: "center" }}
                        >
                          AKTIF
                        </td>
                      );
                     } if (today > end && data.is_active == 0) {
                      return (
                        <td
                          className="reportspp"
                          style={{
                            color: "red",
                            textAlign: "center",
                            boxSizing: "unset",
                          }}
                        >
                          TIDAK AKTIF
                        </td>
                      );
                    
                     } if (today < publish  && data.is_active == 0) {
                      {console.log(publish)}
                      {console.log(end)}
                      {console.log(today)}
                     
                      return (
                        <td
                          className="reportspp"
                          style={{
                            color: "blue",
                            textAlign: "center",
                            boxSizing: "unset",
                          }}
                        >
                          BELUM AKTIF
                        </td>
                      );
                    }
                  })()}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
Pengumuman.propTypes = {
  handleListAnnouncement: PropTypes.func,
  handleListAnnouncementStudent: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
Pengumuman.defaultProps = {
  handleListAnnouncement: noop,
  handleListAnnouncementStudent: PropTypes.func,
  handleDelete: noop,
  user: null,
};
