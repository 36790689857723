import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import PengumumanForm from './components/PengumumanForm.component';

export default class TambahPengumuman extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this._setForm = this._setForm.bind(this);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
        this._getFolderOptions= this._getFolderOptions.bind(this);
        this._getStudentOptions = this._getStudentOptions.bind(this);
        this._getTeacherOptions = this._getTeacherOptions.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onClearForm = this._onClearForm.bind(this);
        this.onRemoveFile = this.onRemoveFile.bind(this);
        // this._getPrmLevels = this._getPrmLevels.bind(this);

        const { user, location } = this.props;
        const { state = {} } = location;
        const { data } = state;
        const { organizations_id, school_unit, user_group } = user;
        const{ levels_id } = school_unit;
        this.state= {
         
          coa: {
            loading: true,
            list: [],
            
          },
          form: {
            value: {
              levels_id: user_group.name !== 'pusat'?levels_id: data.levels_id,
              units_id : user_group.name !== 'pusat'?school_unit.id:data.units_id,
              name: '',
              description:'',
              hyperlink:'',
              publish_date: '',
              end_date: '',
              classrooms: [],
              students:[],
              teachers:[],
              organizations_id: organizations_id,
              
            },
            error: {},
          },
          param: {},
        };
      }

      componentDidMount() {
        const { location, getLevels, getclassrooms, user } = this.props;
        const { state = {} } = location;
        const { organizations_id, school_unit } = user;
        const {isEdit =  false, data} = location.state;
        const { id } = state.data || '';
        const paramTypes = ['classrooms', "levels"];
        if (isEdit && id) {
          this.setState({id:id})
          this._setForm(data.id);
        }
        paramTypes.forEach((type) => {
          this._getParamOptionsAcademics(type);
        });
        
        this._getStudentOptions({units_id: [school_unit.id] });
        this._getFolderOptions();
        this._getTeacherOptions({units_id: [school_unit.id] });
        // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: organizations_id } });


        // this._getclassrooms();
        // getclassrooms();
        // getLevels();
        // if (id) {
        //   this.setState({id:id})
        //   this._handleLoadData(id)
        // }
        

      }

      async _getPrmLevels(filters = {}) {
        const { getPrmLevels, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { units_id, levels_id } = school_unit;
        const res = await getPrmLevels(filters);
        filters.organizations_id = organizations_id;
    
        this.setState((prevState) => ({
          ...prevState,
          param: {
            ...prevState.param,
            levels: res,
          },
        }));
      }


      async _getTeacherOptions(filters = {}) {
        const { getTeacherOptions, user } = this.props;
        const { school_unit } = user;
    
        const res = await getTeacherOptions(filters);
        filters.units_id = school_unit.id;
        this.setState((prevState) => ({
          ...prevState,
          param: {
            ...prevState.param,
            teacher: res,
          },
        }));
      }

      async _setForm(id) {
        const { handleGetAnnouncement, user } = this.props;
        const { organizations_id } = user;
        try {
          const payload = await handleGetAnnouncement({ id });
          const { classrooms, levels_id } = payload;
          this._getParamOptionsAcademics( 'classrooms',  { levels_id: levels_id, organizations_id: organizations_id } );
          this._getStudentOptions({ filters: { classrooms_id: classrooms.map(item => item.value)}} );

          this.setState({
            form: {
              value: {
                id: payload.id,
                levels_id: payload.levels_id,
                name:payload.name,
                hyperlink: payload.hyperlink,
                description: payload.description,
                students: payload.students,
                teachers: payload.teachers,
                classrooms: payload.classrooms,
                // folder: payload.folder,
                publish_date: payload.publish_date,
                end_date: payload.end_date,
                upload: payload.upload,
                
              },
              error: {
                // details: [],
              },
            },
          });
        } catch (err) {
          // err action
        }
      }

      _onClearForm() {
        this.setState({
          form: {
            value: {},
            error: {},
          },
        });
      }

      // async _handleLoadData(id) {
      //   const { getData } = this.props;
      //   this.setState({
      //     form:{
      //       value : this.props.location.state.data,
      //       error : []
      //     }
      // }, () => console.log(this.state));
      // }
      // getData = async(id) => {
      //   const { handleListAnnouncement } = this.props;
      //   const result = await handleListAnnouncement(id);
      //   this.setState({
      //       form:{
      //         value : result[id],
      //         error : []
      //       }
      //   });
      // }
      
  componentDidUpdate(prevProps, prevState) {
    const { form: prevForm } = prevState;
    const { value: prevValue } = prevForm;
    const { form } = this.state;
    const { value } = form;
    const paramType = ['levels', 'classrooms'];
    
    // paramType.forEach((type) => {
    //   let filter = '';
    //   let paramName = ''
    //   if (prevValue[type] !== value[type]) {
    //     if (type === 'levels') {
    //       // filter = 'id';
    //       paramName = 'levels';
    //     } 
    //     else if (type === 'classrooms') {
    //       filter = 'classrooms_id';
    //       paramName = 'classrooms';
    //     } 
    //     this._getParamOptionsAcademics( paramName, { filters: { [filter]: value[type] }} );
    //   }
    // });
  }

      async _getStudentOptions(filters={}) {
        const { getStudentOptions } = this.props;
        const res = await getStudentOptions(filters);
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            student: res,
          },
        }));
        
       
      }

      async _getFolderOptions(filters={}) {
        const { getFolderOptions, user } = this.props;
        const { organizations_id } = user;
        filters.organizations_id = organizations_id;
        const res = await getFolderOptions(filters);
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            folder: res,
          },
        }));
       
      }

      


      async _getParamOptionsAcademics(type, filters = {}) {
        const { getParamOptionsAcademics, user, location } = this.props;
        const { state = {} } = location;
        const { isEdit = false, data, getdata } = state;
        const { organizations_id, school_unit, user_group } = user;
        const { levels_id } = school_unit;
        if (type === "classrooms" || type === "classrooms") {
          filters.organizations_id = organizations_id;
          if( user_group.name == 'pusat'){
            filters.units_id = data.units_id;
          } else {
            filters.units_id = school_unit.id;
          }
          // filters.levels_id = levels_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState((prevState) => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }

      async onSubmit() {
        const { history, handleSaveAnnouncement } = this.props;
        const { form } = this.state;
        const { value } = form;        
        let canSaveA = false;
        const {name, publish_date, end_date, classrooms, students} = form.value;
       
        console.log(form.value)
        console.log(classrooms.length)
        if(name && publish_date && classrooms.length !== 0 && students.length !== 0){
        //  if (publish_date > end_date) {
        //     form.error.publish_date = "Format Tanggal Salah";
        //     form.error.end_date = "Format Tanggal Salah";
        //     canSaveA = false;
        //   } 
        //   else {
            canSaveA = true;

          // }
        }  else {
          canSaveA = false;

        }


        if (canSaveA == false){
          alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        }  else {
          await handleSaveAnnouncement(value);
          history.push('/dashboard/kurikulum/pengumuman');
        }
      }
      async onEdit() {
        const { history, handleEditAnnouncement } = this.props;
        const { form } = this.state;
        const { value } = form;
        let canSaveA = false;
        const {name, publish_date, end_date, classrooms} = form.value;
       
        console.log(form.value)
        console.log(classrooms.length)
        if(name && publish_date && classrooms.length !== 0 ){
        //  if (publish_date > end_date) {
        //     form.error.publish_date = "Format Tanggal Salah";
        //     form.error.end_date = "Format Tanggal Salah";
        //     canSaveA = false;
        //   } 
        //   else {
            canSaveA = true;

          // }
        }  else {
          canSaveA = false;

        }


        if (canSaveA == false){
          alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        }  else {
        const res = await handleEditAnnouncement(value);        
        history.push('/dashboard/kurikulum/pengumuman');
      }
      }
      _onClickSubmit() {
        const { history } = this.props;
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-k13');
        //history.push('/dashboard/kurikulum/laporan/pengumuman');
        // history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
      _onFormChange(event) {
        const { getclassrooms, getLevel, user, form } = this.props;
        const { organizations_id } = user;

        const {
          name,
          value,
          dataset,
          checked,
          type,
          files
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;

        // if(name === 'levels' ) {
        //   getclassrooms({ filters: { classrooms_id: event.target.value }})
        // } 

        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;          
          if(type==="file"){
            formattedValue = files[0]
          }
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        }, () => {
          if (name === 'levels') {
            this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: organizations_id } );
          }
          if (name === 'classrooms') {
            // this.newStudents()

            this._getStudentOptions({ filters: { classrooms_id: value.map(item => item.value)}} );
          }
        });
      }


      onRemoveFile() {
        this.setState((prevState) => {
          return {
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                upload: '',
                file:'',
              }
            }
          }
        });
      }

      newStudents() {
        const {form} = this.state;
        const students_value = form.value.students? form.value.students:'';
        const classrooms_value = form.value.classrooms? form.value.classrooms:'';
        const classrooms_filters = classrooms_value?classrooms_value.map(item => item.value):'';
        

          const students_filter = students_value?students_value.filter((item) => classrooms_filters.includes(item.classrooms_id)):'';
        form.value.students = students_filter
        

        return students_filter;
      }

    

      render() {
        const {form, param, name, classrooms, student} = this.state;
        const { onRemoveFile, handleGetKelas, handleUploadAnnouncement, location,  levels = {}} = this.props;
        const { isEdit = true, data } = location.state;
        const { publish_date, end_date } = form.value;

        let currentDate = new Date().toJSON().slice(0, 10);

        console.log(currentDate)
        console.log(publish_date)
        if (publish_date < currentDate && publish_date!=='' || end_date < currentDate && end_date!=='' || publish_date > end_date) {
          form.error.publish_date = "Format Tanggal Salah";
          form.error.end_date = "Format Tanggal Salah";
        } 
        if (publish_date < end_date && end_date > currentDate || publish_date === end_date && (publish_date!=='' && end_date!=='') || end_date == currentDate || publish_date == currentDate) {
          form.error.publish_date = "";
          form.error.end_date = "";
        }
        if(form.value.classrooms.length == 0){
          delete form.value.students
        }

        const students_value = form.value.students? form.value.students:'';
        const classrooms_value = form.value.classrooms? form.value.classrooms:'';
        const classrooms_filters = classrooms_value?classrooms_value.map(item => item.value):'';
        
        const students_filter = students_value?students_value.filter((item) => classrooms_filters.includes(item.classrooms_id)):'';

        // const students_filter = students_value?students_value.filter((item) => item.classrooms_id?item.classrooms_id:'' == classrooms_filters):'';
        // const students_find = students_value?students_value.find((item) => item.classrooms_id?item.classrooms_id:'' == classrooms_filters):'';
        form.value.students = students_filter

        console.log(classrooms_filters)
        console.log(students_value)
        console.log(students_filter)
       
        return (
          <div className="student-list">
             <form>
              <h1>Pengumuman</h1>
              <PengumumanForm
              form={form}
              // classrooms={classrooms}
              // levels={levels}
              onRemoveFile={this.onRemoveFile}
              onFormChange={this._onFormChange}
              param={param}
              handleUploadAnnouncement = {handleUploadAnnouncement}
              />
              <br></br>
                <div className="manage-registration__footer">
                {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Posting"
                onClick={this.onSubmit}
              />}
                </div>
                </form>
          </div>
        );
      }
}
TambahPengumuman.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetAnnouncement: PropTypes.func,
  getStudentOptions: PropTypes.func,
  getFolderOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahPengumuman.defaultProps = {
  getStudentOptions: noop,
  getFolderOptions: noop,
  getParamOptions: noop,
  handleGetStudent: noop,
  handleGetAnnouncement: noop,
};
