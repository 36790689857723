import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Select, Button, SearchSelect } from "../../../../components/base/index";
import ReactToPrint from "react-to-print";
import logo from '../../../../assets/img/logo-YPL.png';
import { Link } from "react-router-dom";
import * as commonActions from '../../../../states/actions/common.action';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanSPPExcel extends PureComponent {
  constructor(props) {
    super(props);
    
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickStudent = this._onClickStudent.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._onClickSubmit = this._onClickSubmit.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onSearchStudent = this._onSearchStudent.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);
    this._onClickDetails = this._onClickDetails.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      list: [],
      filters:{
      },
      list: [],
      isPrint: true,
      form: {
        value: {
          levels_id,
          listreport: [],
          listreport2: [],
          units_id:'',
          levels_units:'',
          user_id:user.id
        },
        error: {
          listreport: [],
          listreport2: [],
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { content, filters, form } = this.state;
    let savedFilters = JSON.parse(window.localStorage.getItem('invoiceFilters')) || filters;
    let savedForm = JSON.parse(window.localStorage.getItem('invoiceForm')) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }
    console.log(savedFilters)
    console.log(savedForm)
    if (isEmpty(content.list)) {
    }
    this._initializeData(savedFilters);
    if(savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_id: savedForm.value.levels_id,
            units_id: savedForm.value.units_id,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          units_id: savedForm.value.units_id,
        }
      }));
     
      if(user_group.name === 'pusat'){
         this._getPeriodsOptions({units_id: savedForm.value.units_id.value});
         this._getParamOptionsAcademics("classrooms", {
          levels_id: savedForm.value.levels_id,
          organizations_id,
          units_id: savedForm.value.units_id.value
        });
        }
        else {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: savedForm.value.levels_id,
            organizations_id,
            // units_id: savedForm.value.units_id.value
          });
        }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { form } = this.state;
    const { school_unit, user_group, organizations_id } = user
    const paramTypesLevels = ["levels"];
    paramTypesLevels.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
   
    if(user_group.name !== 'pusat'){
      const paramTypes = ["classrooms", "levels"];

      paramTypes.forEach((type) => {
        this._getParamOptionsAcademics(type);
      });
      this._getPeriodsOptions({units_id: school_unit.id});
    }

    await this._getSchoolUnitsOptions();

    if ( organizations_id == 3){
      await this.getPrmPaymentsNon();
    }
    else {
    await this.getPrmPaymentsNonGeneral();
    }

    if (user_group.name == 'pusat'){
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id : savedFilters.classrooms_id,
          jenis : savedFilters.jenis,
          payments_id : savedFilters.payments_id, 
          period : savedFilters.period,
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
    else {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          classrooms_id : savedFilters.classrooms_id,
          jenis : savedFilters.jenis,
          payments_id : savedFilters.payments_id, 
        }
      }), () => { 
        // commonActions.setLoading({ show: true });
        this._onSearchContent() 
      });
    }
   
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      // window.localStorage.setItem('invoiceValue', JSON.stringify(this.state.form));
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    },
    () => {
      if (name === "levels_id") {
        this._getParamOptionsAcademics("classrooms", {
          levels_id: value,
          organizations_id: organizations_id
        });
      }
    });
  }

  _onChangeFilter(e) {
    const { user } = this.props;
    const { form } = this.state;
    const { organizations_id, school_unit } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem('invoiceFilters', JSON.stringify(this.state.filters));
        window.localStorage.setItem('invoiceForm', JSON.stringify(this.state.form));
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id, units_id: school_unit.id },
          });
        }
        if (name === 'units_id') {
          const param = this.state.param.units;
          console.log(value.value)
          const levels_units = param.find(i => i.value == value.value)?param.find(i => i.value == value.value).attributes.levels_id:"";
          
          console.log(levels_units)
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                classrooms_id: "",
                levels_id: levels_units,
                units_id : value,
              }
            },
            filters : {
              ...prevState.filters,
              jenis: "",
            }
          }));
          this._getParamOptionsAcademics("classrooms", {
            levels_id: levels_units, organizations_id: organizations_id, units_id: value.value
          });
          this._getPeriodsOptions({units_id: value.value})
        }

        this._onSearchContent();
      }
    );
  }

  _onClickStudent(data, idx) {
    this._onSearchStudent(data);
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
      data_idx: idx,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }

  _onClickSubmit() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp");
  }

  _onClickEdit(val) {
    const { history, user } = this.props;
    const { organizations_id}  = user;
    // console.log("ini val" +val)
    if(organizations_id == 4 ||  organizations_id == 5){
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general", {data: val, isEdit: true });
    }
    else {
      history.push('/dashboard/tagihan-siswa/create-invoice/tidak-rutin', { data: val, isEdit: true });
    }
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { currents_year } = form.value
    const { jenis, classrooms_id, period, payments_id } = filters;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleReportPaymentsRutin,handleReportPaymentsRutinGeneral, 
            handleReportPaymentsNonRutin, handleReportPaymentsNonRutinNew, handleReportPaymentsNonRutinGeneral,
            handleReportPaymentsNonRutinGeneralRicci } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id} = user;
          const { units_id } = school_unit.id;

          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if (month < 7) {
            year -= 1;
          }

          // let period_label = param.periods.find((i) => i.value == period) ? param.periods.find((i) => i.value == period).label : ""
          // if (period_label && period_label != "") {
          //   let period_idx = period_label.indexOf("-");
          //   let currents_year = period_label.substring(0, period_idx)
          // }

          let result = {};
          let result2 = {};
          if (jenis === "rutin" && period && organizations_id == 3) {
            result = await handleReportPaymentsRutin({
              ...params,
              organizations_id : organizations_id,
              // currents_year : period ? period : year,
              currents_year : currents_year ? currents_year : year,
              classrooms_id : classrooms_id,
              filters,
            });
          } 
          else if (jenis === "rutin" && period && organizations_id !== 3) {
            result = await handleReportPaymentsRutinGeneral({
              ...params,
              organizations_id : organizations_id,
              // currents_year : period ? period : year,
              currents_year : currents_year ? currents_year : year,
              classrooms_id : classrooms_id,
              filters,
            });
          } 
          else if (jenis === "nonrutin" && payments_id && organizations_id == 3) {
            result2 = await handleReportPaymentsNonRutin({
              ...params,
              organizations_id : organizations_id,
              payments_id: payments_id,
              classrooms_id : classrooms_id,
              filters,
            });
          }
          else if (jenis === "nonrutin" && payments_id && organizations_id == 4 || organizations_id == 5) {
            result2 = await handleReportPaymentsNonRutinGeneralRicci({
              ...params,
              organizations_id : organizations_id,
              payments_id: payments_id,
              classrooms_id : classrooms_id,
              filters,
            });
          }
          else if (jenis === "nonrutin" && payments_id && organizations_id !== 3 && organizations_id !== 4 || organizations_id !== 5) {
            result2 = await handleReportPaymentsNonRutinGeneral({
              ...params,
              organizations_id : organizations_id,
              payments_id: payments_id,
              classrooms_id : classrooms_id,
              filters,
            });
          }

          
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: result,
                listreport2: result2,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listreport: [],
                listreport2: [],
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  async _onSearchStudent(data) {
    const { filters, form } = this.state;
    const { jenis, classrooms_id, period, payments_id } = filters;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleReportPaymentsNonRutinNew } = this.props;
          const { user } = this.props;
          const { organizations_id} = user;

          let result3 = {};
          result3 = await handleReportPaymentsNonRutinNew({
            organizations_id : organizations_id,
            payments_id: payments_id,
            temps_id : data.no_va,
            // filters,
          });
          
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listcicilan: result3,
                student_name: data.name,
                student_id: data.no_va,
              },
            },
          }));
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listcicilan: [],
                student_name: "",
                student_id: "",
              },
            },
          }));
        }
        // finally {
        //   commonActions.setLoading({ show: false }); 
        // }
      }
    );
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit, user_group } = user;
    const { form } = this.state;
    const { units_id } = form.value;
    
    if (user_group.name !== 'pusat'){
      if (type === "classrooms" || type === "classrooms") {
        filters.organizations_id = organizations_id;
        {units_id?filters.units_id = units_id : filters.units_id = school_unit.id}
        
        // filters.units_id = 121;
      } else {
        delete filters.organizations_id;
      }
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          }
        }))
      }
    });
    
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }
  async _getSchoolUnitsOptions(filters={}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find(item => item.value == units_id); 
    
    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '', 
        }
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  _onClickDetails(val) {
    const { history } = this.props;
    const { form, filters, param, page, isPrint, data_idx } = this.state;
    const { listreport, listreport2, years_now, currents_year } = form.value;
    history.push("/dashboard/tagihan-siswa/laporan-spp-excel", {
      data1: listreport,
      data2: listreport2,
    });
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
     const { form, filters, param, page, isPrint, data_idx } = this.state;
     const { listreport, listreport2, years_now, currents_year } = form.value;
     const { jenis, payments_id, units_id, classrooms_id } = filters;
     const { user } = this.props
     const { school_unit, organization, organizations_id, user_group } = user;
     const { logo_right } = organization;
     const { prm_payments } = param;
     let prm_payments_main = prm_payments?prm_payments.filter((item) => item.is_type == 1):'';
 
     const componentRef = React.createRef();
     
     const printTest = `
     @media print {
       @page {
         page-break-inside: avoid;
       }
       * {
         -webkit-print-color-adjust: exact;
       }
     }`;
 
 
     const prm_jenis = [
       { label: organizations_id ==3?'SPP':'RUTIN', value: 'rutin'},
       { label: organizations_id ==3?'DPP/UPP':'NON RUTIN', value: 'nonrutin'},
     ];
     const bulan = [
       { label: 'Juli'},
       { label: 'Agustus'},
       { label: 'September'},
       { label: 'Oktober'},
       { label: 'November'},
       { label: 'Desember'},
       { label: 'Januari'},
       { label: 'Februari'},
       { label: 'Maret'},
       { label: 'April'},
       { label: 'Mei'},
       { label: 'Juni'},
     ];
 
     var lunas = 0
     var ttllunas = 0
     var subtotal = 0
     var total = 0
     var nominal = 0
     var totalSeluruh = 0
     let data_tr = {}
     let data_details = {}
     let payment_name = {}
     var classrooms_name;
     var payments_name;
 
     map(param.classrooms, (data, idx) => {
       if (data.value == classrooms_id) {
         classrooms_name = data.label
       }
     })
     map(param.prm_payments, (data, idx) => {
       if (data.value == payments_id) {
         payments_name = data.label
       }
     })
     var title = ""
     if (jenis == "rutin") {
       title = organizations_id ==3?'SPP':'RUTIN'
     }
     if (jenis == "nonrutin") {
       title = organizations_id ==3?'DPP/UPP':'NON RUTIN'
     }
 
     const units_label = units_id?units_id.label:'';
     const units_value = units_id?units_id.value:'';
 
 
     return (
       <div className="student-list">
         <div className="budget__title">
           <h1>Laporan Pembayaran</h1>
           <hr></hr>
         </div>
 {/* 
         <div className="absensi-rekap__custom-form">
           {(user_group.name === 'pusat')? 
             <div className="absensi-rekap__custom-form-row">
               <div className="absensi-rekap__custom-form-row__field">
               <SearchSelect
                 noMargin
                 async={false}
                 name="units_id"
                 list={param.units}
                 inputArray
                 onClick={this._onChangeFilter}
                 placeholder="Pilih Unit"
                 value={form.value.units_id}
                 rightIcon="icon-search"
                 label="Pilih Unit"
               />
               </div>
             </div>
             :''}
           <div className="absensi-rekap__custom-form-row">
             <div className="absensi-rekap__custom-form-row__field">
               <Select
                 name="jenis"
                 label="Jenis Pembayaran"
                 onChange={this._onChangeFilter}
                 data={prm_jenis}
                 placeholder="Pilihan"
                 value={jenis}
                 disabled={(user_group.name !== 'pusat' || units_value)? false : true}
               />
             </div>
             <div className="absensi-rekap__custom-form-column__field">
               {(jenis === "nonrutin") && (
                   <Select
                     name="payments_id"
                     label="Nama Pembayaran"
                     onChange={this._onChangeFilter}
                     data={organizations_id == 3 ? prm_payments : prm_payments_main}
                     placeholder={"Pilihan"}
                     value={payments_id}
                   />
                 )}
             </div>
           </div>
         </div> */}
 
         <br></br>
         <div className="beginning-balance__button-wrapper">
           <ReactToPrint
             pageStyle={printTest}
             onBeforeGetContent={()=> {
               return new Promise((resolve) => {
                 setTimeout(() => {
                   this.setState(
                     { isPrint: false },
                     resolve
                   );
                 }, 500);
               });
             }}
             onAfterPrint={()=> this.setState({isPrint: true})}
             trigger={() => (
               // <div className="manage-registration__footer">
                 <Button title="Cetak" />
               // </div>
             )}
             content={() => componentRef.current}
           />
           &nbsp;
           <div style={{marginLeft: '0rem'}} className="student-list__button">
             <ReactHTMLTableToExcel
                 id="export-to-xls-button"
                 className="button "
                 table="printable"
                 filename={`Laporan Pembayaran Non Rutin Kelas ${classrooms_name ? classrooms_name : "-"}`}
                 sheet={classrooms_name ? classrooms_name : "-"}
                 buttonText="Unduh ke Excel"
               />
 
               </div>
         </div>
         {/* {isPrint? */}
         <form style={{pageBreakInside: 'avoid', padding: '20px' }} ref={componentRef}>
           <div>
             <table className="table-report-header" >
               <tr>
                 <th rowSpan="2">
                   <img style={{width:"70px"}} src={logo_right} />
                 </th>
                 <th style={{ paddingRight: '10rem' }}>
                   <h2>{user_group.name === 'pusat' ? units_label : school_unit.name}</h2>
                 </th>
               </tr>
               <tr>
                 <th style={{ paddingRight: '10rem' }}>
                   <h3>LAPORAN PEMBAYARAN PPDB</h3>
                 </th>
               </tr>
             </table>
 
             {(() => {
               if (!isEmpty(listreport) && jenis == "rutin") {
                 return (
                   <>
                   <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                     <tr>
                       <td>Jenis Pembayaran</td>
                       <td>&emsp; : {title}</td>
                     </tr>
                     <tr>
                       <td>Tahun Ajaran</td>
                       <td>&emsp; : {currents_year ? currents_year+"/"+(Number(currents_year)+1) : "-"}</td>
                     </tr>
                   </table>
                   <br></br>
                   <table className="tablespp" id="printable">
                     <thead>
                       <tr>
                         <th rowSpan={2}>No</th>
                         <th rowSpan={2}>No VA</th>
                         <th rowSpan={2}>Nama Siswa</th>
                         <th colSpan={12}>Rincian Pembayaran</th>
                         <th rowSpan={2}>Total Pembayaran</th>
                       </tr>
                       <tr>
                         {map(bulan, (bln, idx) => (
                           <th>{bln.label}</th>
                         ))}
                       </tr>
                     </thead>
                     <tbody>
                       {map(listreport, (data, idx) => (
                         <tr key={`budget_row_${idx}`}>
                           {(() => {
                             data.id = data.no_va
                             data.entrance_year = currents_year
                             subtotal = 0
                           })()}
                           <td key={`table_index_${idx}`} style={{textAlign:"center"}}>
                             {idx + 1}
                           </td>
                           <td>{data.no_va}</td>
                           <td style={{textTransform:"capitalize"}}>{capitalize(data.name)}</td>
                           {(() => {
                             if (isEmpty(data.tr_invoices)) {
                               return (
                                 <td colSpan={12}>Invoice belum dibuat.</td>
                               )
                             } else {
                               return (
                                 map(data.tr_invoices, (data_detail, idx_detail) => {
                                   if (data_detail.payments_date !== null) {
                                     return (
                                       <td className="reportspp" style={{color:"green", textAlign:"center"}}>LUNAS</td>
                                     )
                                   } else {
                                     subtotal = subtotal + Number(data_detail.nominal)
                                     return (
                                       <td className="reportspp">
                                         <Link
                                           to={{
                                             pathname: "/dashboard/tagihan-siswa/create-invoice/rutin",
                                             state: {
                                               getdata: data_detail,
                                               data: data, 
                                               isEdit: true,
                                             },
                                           }}>
                                           {commaFormatted(data_detail.nominal)}
                                         </Link>
                                       </td>
                                     )
                                   }
                                 })
                               )
                             }
                           })()}
                           <td className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px"}}>{commaFormatted(subtotal)}</td>
                           {(() => {
                             total = total + subtotal
                           })()}
                         </tr>
                       ))}
                       <tr>
                         <th colSpan={15}>Total</th>
                         <th className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(total)}</th>
                       </tr>
                     </tbody>
                   </table>
                   </>
                 )
               }
               else if (!isEmpty(listreport2) && jenis == "nonrutin"){
                 return (
                   <div>
                     <table className="laporan-ki4__table-test" style={{width:"unset"}}>
                     <tr>
                        <td>Nama Pembayaran</td>
                        <td>&emsp; : {payments_name ? payments_name : "-"}</td>
                    </tr>
                       <tr>
                          <td>Kelas</td>
                          <td>&emsp; : {classrooms_name ? classrooms_name : "-"}</td>
                        </tr>
                     </table>
                     <br></br>
                     <table className="tablespp" id="printable">
                       <thead>
                         <tr>
                           <th>No VA</th>
                           <th>Nama Siswa</th>
                           <th>Nama Pembayaran</th>
                           <th>Cicilan</th>
                           <th>Nominal</th>
                           <th>Tanggal Bayar</th>
                           <th>Keterangan</th>
                         </tr>
                       </thead>
                         {map(listreport2, (data, idx) => (
                       <tbody>
 
                           <tr key={`budget_row_${idx}`}>
                             {(() => {
                               data_tr = data.tr_payment_report?data.tr_payment_report:'';
                               data_details = data_tr[0]?data_tr[0].paymentdetails:'';
                               payment_name = data_tr[0]?data_tr[0].payment_name:'';
                               // lunas = 0
                               // subtotal = 0
                               nominal = 0
                             })()}
                             <td rowSpan={data_details.length+1}>{data.no_va}</td>
                             <td style={{textTransform:"capitalize"}} rowSpan={data_details.length+1}>{capitalize(data.name)}</td>
                             {isEmpty(data_details)?
                             <td colSpan={12}>Invoice belum dibuat.</td>
                             :                             
                             <td style={{textTransform:"capitalize"}} rowSpan={data_details.length+1}>{capitalize(payment_name)}</td>
                           }
                             </tr>
                             {isEmpty(data_details)?
                             '':
                             <>
                             {map(data_details, (list, idx) => (
                               <>
                               <tr>
                               <td>{list.cicilan}</td>
                               <td>{commaFormatted(list.nominal)}</td>
                               <td>{list.payments_date?list.payments_date:''}</td>
                               {list.payments_date?
                               <td style={{color:"green", textAlign:"center"}}>LUNAS</td>
                               :
                               <td style={{color:"red", textAlign:"center"}}>BELUM LUNAS</td>
 
                             }
                               
                             </tr>
                             {(() => {
                                  if (list.payments_date !== null) {
                                   lunas = lunas + Number(list.nominal)
                                 } else {
                                   subtotal = subtotal + Number(list.nominal)
                                 }
                                totalSeluruh = Number(lunas) + Number(subtotal)
                                nominal = nominal + Number(list.nominal)
                               })()}
                             </>
                             ))}
                             
                           <tr>
 
                               <td colSpan={4}>Total Nominal Persiswa</td>
                               <td colSpan={3}>{commaFormatted(nominal)}</td>
                             </tr>
                             </>
                             }
 
                       </tbody>
                         ))}
 
                        
 
                         {console.log(lunas)}
                         {console.log(subtotal)}
                         {console.log(totalSeluruh)}
 
                         <tr>
                           <th colSpan={4}>Total Sisa Bayar</th>
                           <th colSpan={3} className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(subtotal)}</th>
                         </tr>
                         <tr>
                           <th colSpan={4}>Total Sudah Bayar</th>
                           <th colSpan={3} className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(lunas)}</th>
                         </tr>
                         <tr>
                           <th colSpan={4}>TOTAL</th>
                           <th colSpan={3} className="nominal" style={{width: "90px", minWidth: "90px", maxWidth: "90px", textAlign: "right"}}>{commaFormatted(totalSeluruh)}</th>
                         </tr>
                     </table>
                   </div>
                 )
               }
               else if (jenis == undefined || jenis == "") {
                 return (
                   <h2 style={{textAlign:"center"}}>Jenis Pembayaran belum dipilih.</h2>
                 )
               }
               else {
                 return (
                   <h2 style={{textAlign:"center"}}>Data {currents_year} belum ada.</h2>
                 )
               }
             })()}
           </div>
         </form>
 
       </div>
     );
   }
}
LaporanSPPExcel.propTypes = {
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanSPPExcel.defaultProps = {
  handleGetStudent: noop,
};
